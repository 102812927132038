import { createSlice } from "@reduxjs/toolkit";
export const lottoSlice = createSlice({
  name: "lotto",
  initialState: {
    lottoType: [],
    lottoDraw: [],
    lottoCategory: [],
    lottoDrawPattern: [],
    lottoTab: 0,
  },
  reducers: {
    setLottoTypes: (state, action) => {
      state.lottoType = action.payload;
    },
    setLottoDraws: (state, action) => {
      state.lottoDraw = action.payload;
    },
    setLottoCategorys: (state, action) => {
      state.lottoCategory = action.payload;
    },
    setLottoDrawPatterns: (state, action) => {
      state.lottoDrawPattern = action.payload;
    },
    setLottoTab: (state, action) => {
      state.lottoTab = action.payload;
    },
  },
});

export const {
  setLottoTypes,
  setLottoDraws,
  setLottoCategorys,
  setLottoDrawPatterns,
  setLottoTab,
} = lottoSlice.actions;

export const getLottoTypes = (state) => state.lotto.lottoType;
export const getLottoDraws = (state) => state.lotto.lottoDraw;
export const getLottoCategorys = (state) => state.lotto.lottoCategory;
export const getLottoDrawPatterns = (state) => state.lotto.lottoDrawPattern;
export const getLottoTab = (state) => state.lotto.lottoTab;

export default lottoSlice.reducer;
