import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    login: false,
    loggedIn: false,
    accessRole: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.login = true;
      state.user = action.payload;
    },
    setLogOut: (state, action) => {
      state.login = false;
      state.user = {};
    },
    setloggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setAccessRole: (state, action) => {
      state.accessRole = action.payload;
    },
  },
});

export const { setUser, setLogOut, setCredit, setloggedIn, setAccessRole } =
  userSlice.actions;
export const getMember = (state) => state.user.user;
export const getLogin = (state) => state.user.login;
export const getLoggedIn = (state) => state.user.loggedIn;
export const getAccessRole = (state) => state.user.accessRole;

export default userSlice.reducer;
